import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import {
  Link,
  Trans,
  useTranslation,
  useI18next,
} from "gatsby-plugin-react-i18next";
import Layout from "../pages/components/layout";
import AudioPlayer from "../pages/components/audioplayer";
import "../css/index.css";
import Leaves from "../images/sketches/leaves.svg";
import Pig from "../images/sketches/pig-white.svg";
import Microphone from "../images/sketches/microphone.svg";
import Apple from "../images/sketches/apple-white.svg";
import Next from "../images/logos/next-button.svg";
import Previous from "../images/logos/previous-button.svg";
import Slide from "react-reveal/Slide";
import TestAudio from "../audio/WATERFORD Stop8.mp3";

const AppleMarket = () => {

  const { languages, changeLanguage } = useI18next();
  const { t } = useTranslation();

  return (
    <Layout pageTitle="Apple Market">
      <div className="lg:hidden">

        
      <Helmet>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <title>Waterford Digital Story Trail - Apple Market</title>
      <meta name="description" content="Apple Market. A location on digital story trail celebrating the history of Waterford." />
      <meta property="og:title" content="Waterford Digital Story Trail - Apple Market" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://digitalstorytrail.visitwaterford.com/applemarket" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
      </Helmet>


      <div className="w-full bg-waterford-ocean h-24"></div>
      <section className="applemarket-bg w-full">
        <div className="w-9/12">
          <h1 className="font-lower p-6 text-white">
            <span className="font-heading text-xl"><Trans>AppleMarket</Trans></span>
            <br />
            <span className="font-bold text-4xl"><Trans>AppleMarketSubtitle</Trans></span>
          </h1>
        </div>
        <div className="pt-64 text-center">
          <div className="text-center">
            <AudioPlayer url={TestAudio} transcript={'/transcripts/appletranscript'}/>
          </div>
        </div>
      </section>

      <div className="pt-16 bg-waterford-ocean"></div>

      <section className="location-hero py-16">
        <div class="tab-content" id="tabs-tabContentJustify">
          <div
            class="tab-pane fade show active"
          >
            <div className="w-7/12">
              <h1 className="font-lower text-white p-6">
                <br />
                <span className="font-bold text-4xl"><Trans>DidYouKnow</Trans></span>
              </h1>
            </div>
            <div className="text-waterford-red w-6/6 py-6">
              <div className="grid grid-cols-4 gap-0 font-lower">
                <h3 className="text-8xl ml-6 lg:mx-auto">1</h3>
                <p className="text-xl col-span-2 font-bold mt-2">
                  <Trans>AppleMarketFactOne</Trans>
                </p>
              </div>
              <div className="w-4/6 py-6 ml-6 lg:mx-auto font-heading">
                <p className="text-white text-sm">
                <Trans>AppleMarketFactContentOne</Trans>
                </p>
              </div>
              <div className="mr-0 md:mr-auto py-6 grid grid-cols-3">
                <Slide up>
                  <div className="w-full"></div>
                  <div className="w-full"></div>
                  <img
                    className="w-full col-span-1"
                    src={Microphone}
                    alt="Microphone"
                  />
                </Slide>
              </div>
            </div>

            <div className="text-waterford-orange w-6/6 py-6">
              <div className="grid grid-cols-4 gap-0 font-lower">
                <div></div>
                <p className="text-xl col-span-2 font-bold mt-2">
                <Trans>AppleMarketFactTwo</Trans>
                </p>
                <h3 className="text-8xl lg:mx-auto">2</h3>
              </div>
              <div className="w-6/6 py-6 grid grid-cols-4 lg:mx-auto font-heading">
                <div></div>
                <p className="text-white text-sm col-span-2">
                <Trans>AppleMarketFactContentTwo</Trans>
                </p>
              </div>
              <div className="mr-0 md:mr-auto py-6 grid grid-cols-3">
                <Slide left>
                  <img className="w-full col-span-2" src={Pig} alt="Pig" />
                  <div className="w-full"></div>
                </Slide>
              </div>
            </div>

            <div className="text-waterford-cyan w-6/6 py-6">
              <div className="grid grid-cols-4 gap-0 font-lower">
                <h3 className="text-8xl ml-4 lg:mx-auto">3</h3>
                <p className="text-xl col-span-2 font-bold mt-2">
                <Trans>AppleMarketFactThree</Trans>
                </p>
              </div>
              <div className="w-4/6 py-6 ml-6 lg:mx-auto font-heading">
                <p className="text-white text-sm">
                <Trans>AppleMarketFactContentThree</Trans>
                </p>
              </div>
            </div>

            <div className="text-waterford-blue w-6/6 py-6">
              <div className="w-7/12 pt-16">
                <h1 className="font-lower text-white p-6">
                  <span className="font-heading text-xl"><Trans>AppleMarket</Trans></span>
                  <br />
                  <span className="font-bold text-4xl"><Trans>Timeline</Trans></span>
                </h1>
              </div>
              <div className="text-waterford-red w-6/6 py-6 px-6">
                <div className="py-6 grid grid-cols-3">
                  <Slide left>
                    <img
                      className="w-full col-span-2"
                      src={Apple}
                      alt="Apple"
                    />
                    <div className="w-full"></div>
                  </Slide>
                </div>
                <div className="grid grid-cols-2 gap-0 font-lower text-right">
                  <div></div>
                  <h3 className="text-6xl lg:mx-auto">1382</h3>
                </div>
                <div className="py-6 ml-6 lg:mx-auto font-heading grid grid-cols-3 text-right">
                  <div></div>
                  <p className="text-white text-sm col-span-2">
                  <Trans>AppleMarketTimelineOne</Trans>
                  </p>
                </div>
              </div>

              <div className="bg-cyan-circle text-waterford-ocean w-6/6 py-16">
                <div className="py-6 grid grid-cols-3">
                <div className="w-full"></div>
                <Slide up>
                  <img
                    className="w-full col-span-2 pb-12"
                    src={Leaves}
                    alt="Merchant Ship"
                  />
                  </Slide>
                </div>
                <div className="grid grid-cols-2 gap-0 font-lower px-6">
                  <h3 className="text-6xl lg:mx-auto">1901</h3>
                  <div></div>
                </div>
                <div className="py-6 ml-6 lg:mx-auto font-heading pb-12 w-7/12">
                  <p className=" text-sm">
                  <Trans>AppleMarketTimelineTwo</Trans>
                  </p>
                  <div></div>
                </div>
              </div>

              <div className="text-waterford-red w-6/6 py-6">
                <div className="grid grid-cols-2 gap-0 font-lower px-6 text-right">
                  <div></div>
                  <h3 className="text-6xl lg:mx-auto">2000s</h3>
                </div>
                <div className="py-6 ml-6 lg:mx-auto font-heading grid grid-cols-3 px-6 text-right">
                  <div></div>
                  <p className="text-white text-sm col-span-2">
                  <Trans>AppleMarketTimelineThree</Trans>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2">
          <div>
            <Link to="/watchtower">
              <img className="w-2/6 mx-auto" src={Previous} alt="Previous" />
            </Link>
            <p className="text-center font-heading pt-4 text-white">
              <Trans>PreviousStop</Trans>
            </p>
          </div>
          <div>
            <Link to="/arundelsquare">
              <img className="w-2/6 mx-auto" src={Next} alt="Next" />
            </Link>
            <p className="text-center font-heading pt-4 text-white">
              <Trans>NextStop</Trans>
            </p>
          </div>
        </div>
      </section>
    </div>
    </Layout>
  );
};

export default AppleMarket;


export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
